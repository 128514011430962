

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdf5ff;
}

.app-wrapper {
  margin: 0 auto;
  max-width: 480px;
  height: 100vmax;
  background-image: url("../pgnew.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size:cover ;
}
.fixedmenu {

  max-width: 480px;
}
.home-wrapper {
  height: 93vmax;
  text-align: center;
  margin: 0 auto;
}
.breadcrumbnav{
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0;
  padding: 0;
  background-color: #3a4932;
  min-height: 70px;
  width: 100%;
}
.breadcrumbnav .btnback{
  color: #f8db94;
  background-color: #3a4932;
  min-height: 50px;
  padding: 0;
}
.breadcrumbnav .addback{
  color: #f8db94;
  background-color: #3a4932;
  min-height: 50px;
  padding: 0;
  margin-right: 8px;
  margin-left: 8px;
}
.form-wrapper {
  height: 93vmax;
  text-align: center;
  margin: 0 auto;
}

.form-wrapper form {
  text-align: center;
  margin: 0 auto;
}

.form-wrapper form input {
  text-align: center;
  margin: 0 auto;
}

.form-wrapper form label {
  text-align: center;
  margin: 0 auto;
}

.form-wrapper form .form-content {

  text-align: center;
  margin: 0 auto;
}

.form-wrapper form input {
  text-align: left;
}
.form-wrapper form label {
  text-align: left;
}


.App-logo {
  margin-top:20px;
  height: 20vmin;
  pointer-events: none;
}

.App-logo2 {
  margin-top:5px;
  pointer-events: none;
  height: 70px;
}


.home_btn-rub {
  margin-top: 30%;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
