.form-container{
    width: 90%;
    margin: 0 auto;
}

.inputimgplante{
    opacity: 0;
    left: 20px;
    top: 0px;
    position: absolute;
    z-index: 10;
    width: 100px;
    height: 100px;
}
.inputTextAria  {

    width: 90%;
    height: auto;
    overflow: hidden;
    padding: 5px;
    margin: 10px auto 0px;
    border: 1px solid #bfbebe;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    box-shadow: -10px -10px 30px 0 #fff, 10px 10px 30px 0 #1d0dca17;
}
.inputTextAria:hover  {
    background: #f8f8ff;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}
.inputAddText, .inputAddPassword {
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: #2a1f62;
    display: flex;
    font-family: "Cascadia Code", Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 18px;
    justify-content: center;
    line-height: 1.5rem;
    padding: 15px;
    position: relative;
    text-align: left;
    transition: .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;

    width: 90% !important;
    word-break: normal;
    word-spacing: normal;
}
.inputAddText:hover, .inputAddPassword:hover {
    background: #f8f8ff;
}

@media (min-width: 768px) {
    .inputAddText, .inputAddPassword {
        padding: 24px;
    }
}


/* CSS */
.button-68 {
    appearance: none;
    backface-visibility: hidden;
    background-color: #27ae60;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.button-68:hover {
    background-color: #1e8449;
    opacity: 1;
    transform: translateY(0);
    transition-duration: .35s;
}

.button-68:active {
    transform: translateY(2px);
    transition-duration: .35s;
}

.button-68:hover {
}
