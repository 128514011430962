.home-wrapper {
    background-color: #263D2B;
    height: 100vh;
}
.search {
    width: 90%;
    margin-bottom: 20px;
    padding: 15px;
    border: none;
    border-radius: 30px;
    font-size: 14px;
}
