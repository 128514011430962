

.myhome-wrapper {
    text-align: center;
    margin: 0 auto;
    background-color: transparent !important;
    height: 100vh;
}
.logo_section {
    text-align: center;
}

ul.home_btn-rub {
    width: 70%;
    padding: 0;
    margin: 10% auto;
}

ul.home_btn-rub li a {
    text-decoration: none;
    color: #f8db94;
}
ul.home_btn-rub li button {
    text-decoration: none;
    color: #f8db94;
}


ul.home_btn-rub li  {
     margin: 15px 0;
}
/* CSS */
.btn-rub {
    align-items: center;
    background: #6D734D;
    border: 1px solid #6D734D;
    border-radius: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    color: #EEE3AD;
    cursor: pointer;
    display: flex;
    font-family:   "Ubuntu Mono", "Roboto", monospace;
    font-size: 1.2rem;
    justify-content: center;
    line-height: 1.5rem;
    padding: 30px 40px;
    position: relative;
    text-align: left;
    transition: .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
    width: auto;
    word-break: normal;
    word-spacing: normal;
}

.btn-rub:hover {
    background: #6d734d;
    box-shadow: -4px -4px 15px 0 #4e4e4e, 4px 4px 15px 0 #696969f5
}

@media (min-width: 768px) {
    .btn-rub {
        padding: 34px;
    }
}


/* Parent background */
