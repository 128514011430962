

.logo_section{
    text-align: center;
}

.notif-content {
    position: relative;
    z-index: 10;
    overflow-y: scroll;
    max-height: 490px;
}
.notif-content .center {
    position: absolute;
    left: 50%;
    top: 50%;
}

.notif-content .square-main {
    position: absolute;
    height: 300px;
    width: 300px;
    left: 50%;
    top: 50%;
    background: #FFFFFF;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    box-shadow: 7px 7px 3px 0px #4898CE;
}

.notif-content .square-header {
    height: 100px;
    width: 300px;
    background: #20c997;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.notif-content .header-notification {
    color: #FFFFFF;
    font-size: 24px;
}

.notif-content .clickable {
    cursor: pointer;
}

.notif-content .square-left {
    width: 130px;
    height: 225px;
    background: #199FFA;
    position: absolute;
    left: -150px;
    top: -110px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 5px 5px 4px 0px #4898CE;
}

.notif-content .active {
    animation: 0.4s reveal-sidebar ease-out forwards;
}

.notif-content .hide {
    animation: 0.4s hide-sidebar ease-in forwards;
}
.notif-content .content-spacing {
    min-height: 50px;
    margin: 20px;
    margin-left: 30px;
    color: #BEBEBE;
}

.notif-content .line {
    background: #DDD;
    position: absolute;
    left: 27px;
    height: 125vh;
    width: 5px;
    border-radius: 10px 10px 10px 10px;
    top: 0;
    z-index: -2;
}

.notif-content .circle {
    height: 14px;
    width: 14px;
    border: 3px solid #20c997;
    position: absolute;
    border-radius: 50%;
    left: 18px;
    margin: 5px;
    background: #FFFFFF;
}

.notification-content {
    min-height: 50px;
}

.notification-content .p {
    text-align: left;
    color: #605c5c;
    border:1px solid #dedddd;
    border-radius: 10px;
    padding: 10px;
    line-height:24px;
    -webkit-box-shadow: -11px 16px 16px -12px rgba(0,0,0,0.54);
    -moz-box-shadow: -11px 16px 16px -12px rgba(0,0,0,0.54);
    box-shadow: -11px 16px 16px -12px rgba(0,0,0,0.54);
}
.notification-content p {
    text-align: left;
    color: #605c5c;
    border-radius: 10px;
    padding: 10px;
    line-height: 24px;
}

#custommodal-dialog .MuiDialog-container .MuiDialog-paper {
     width:90% ;
     height: 80vh;
}
