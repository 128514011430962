
.inputLoginText, .inputLoginPassword {
    border: 0;
    border-radius: 8px;
    box-shadow: -10px -10px 30px 0 #fff, 10px 10px 30px 0 #1d0dca17;
    box-sizing: border-box;
    color: #2a1f62;
    display: flex;
    font-family: "Cascadia Code", Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 18px;
    justify-content: center;
    line-height: 1.5rem;
    padding: 15px;
    position: relative;
    text-align: left;
    transition: .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
    width: max-content;
    word-break: normal;
    word-spacing: normal;
}
.inputLoginText:hover, .inputLoginPassword:hover {
    background: #f8f8ff;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}

@media (min-width: 768px) {
    .inputLoginText, .inputLoginPassword {
        padding: 24px;
    }
}


/* CSS */
.button-68 {
    appearance: none;
    backface-visibility: hidden;
    background-color: #27ae60;
    border-radius: 8px;
    border-style: none;
    box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 13px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.button-68:hover {
    background-color: #1e8449;
    opacity: 1;
    transform: translateY(0);
    transition-duration: .35s;
}

.button-68:active {
    transform: translateY(2px);
    transition-duration: .35s;
}

.button-68:hover {
    box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}


.cookiesContent {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 70px;
    margin: 0 auto;

}
.cookiesContent  button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
}
.cookiesContent img {
    width: 150px;
    margin-bottom: 15px;
}
.cookiesContent p {
    margin-bottom: 40px;
    font-size: 18px;
}
.cookiesContent button.accept {
    background-color: #009688;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px #4caf50;
    margin: 0 auto;
}
